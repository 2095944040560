import { RTContainer, RTGrid } from "rt-serverless-ui";

import { RTBox, RTTypography } from "rt-serverless-ui";
function Typography() {
  return (
    <RTBox component="section" py={8}>
      <RTContainer>
        <RTTypography variant="h2" mb={6}>
          Typography - Font Family Roboto
        </RTTypography>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Heading 1
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9}>
            <RTTypography variant="h1">H1 Material Kit</RTTypography>
          </RTGrid>
        </RTGrid>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Heading 2
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9}>
            <RTTypography variant="h2">H2 Material Kit</RTTypography>
          </RTGrid>
        </RTGrid>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Heading 3
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9}>
            <RTTypography variant="h3">H3 Material Kit</RTTypography>
          </RTGrid>
        </RTGrid>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Heading 4
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9}>
            <RTTypography variant="h4">H4 Material Kit</RTTypography>
          </RTGrid>
        </RTGrid>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Heading 5
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9}>
            <RTTypography variant="h5">H5 Material Kit</RTTypography>
          </RTGrid>
        </RTGrid>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Heading 6
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9}>
            <RTTypography variant="h6">H6 Material Kit</RTTypography>
          </RTGrid>
        </RTGrid>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Lead Text
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9}>
            <RTTypography variant="body1" color="text">
              I will be the leader of a company that ends up being worth billions of dollars,
              because I got the answers. I understand culture. I am the nucleus. I think that&apos;s
              a responsibility that I have, to push possibilities, to show people, this is the level
              that things could be at.
            </RTTypography>
          </RTGrid>
        </RTGrid>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Paragraph
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9}>
            <RTTypography variant="body2" color="text">
              I will be the leader of a company that ends up being worth billions of dollars,
              because I got the answers. I understand culture. I am the nucleus. I think that&apos;s
              a responsibility that I have, to push possibilities, to show people, this is the level
              that things could be at.
            </RTTypography>
          </RTGrid>
        </RTGrid>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Small
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9} lineHeight={1}>
            <RTTypography variant="button" color="text">
              I will be the leader of a company that ends up being worth billions of dollars,
              because I got the answers. I understand culture. I am the nucleus. I think that&apos;s
              a responsibility that I have, to push possibilities, to show people, this is the level
              that things could be at.
            </RTTypography>
          </RTGrid>
        </RTGrid>
        <RTGrid container alignItems="center" py={2}>
          <RTGrid item xs={12} sm={3}>
            <RTTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
              Tiny
            </RTTypography>
          </RTGrid>

          <RTGrid item xs={12} sm={9} lineHeight={1}>
            <RTTypography variant="caption" color="text">
              I will be the leader of a company that ends up being worth billions of dollars,
              because I got the answers. I understand culture. I am the nucleus. I think that&apos;s
              a responsibility that I have, to push possibilities, to show people, this is the level
              that things could be at.
            </RTTypography>
          </RTGrid>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default Typography;
