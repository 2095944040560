import * as React from "react";
import { RTTableColums } from "rt-serverless-ui";
import { useMovieData } from "./useMovieData";

const VISIBLE_FIELDS = ["title", "company", "director", "year", "cinematicUniverse"];

export default function TableOne() {
  const data = useMovieData();
  const columns = React.useMemo(
    () => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [data.columns]
  );
  return <RTTableColums rows={data.rows} columns={columns} pageSizeOptions={[5, 10, 20]} />;
}
