// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  RTAlert,
  RTBox,
  RTTypography,
  RTDivider,
  RTLink,
  RTTable,
  RTButton,
} from "rt-serverless-ui";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useLocation, NavLink } from "react-router-dom";
// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import BaseLayout from "layouts/sections/components/BaseLayout";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

function SnackbarAPI() {
  const columns = [
    { field: "id", headerName: "Name", width: 150, sortable: false },
    {
      field: "Type",
      headerName: "Type",
      width: 130,
      sortable: false,
    },
    {
      field: "Default",
      headerName: "Default",
      width: 130,
      sortable: false,
    },
    {
      field: "Description",
      headerName: "Description",
      disableColumnMenu: true,
      width: 700,
      sortable: false,
      renderCell: (params) => (
        <p>
          {params.value.start}
          <a href={params.value.url} target="_blank" rel="noopener" style={{ color: "#1890ff" }}>
            {params.value.link}
          </a>
          {params.value.end}
        </p>
      ),
    },
  ];

  const rows = [
    {
      id: "color",
      Type: `
      'primary'
      'secondary'
      'info'
      'success'
      'warning'
      'error'
      'light'
      'dark'`,
      Default: "info",
      Description: {
        start: "Change the RTSnackbar type.",
      },
    },
    {
      id: "icon*",
      Type: "node",
      Default: "-",
      Description: {
        start: "Used to set the RTSnackbar icon, its a required prop.",
      },
    },
    {
      id: "title*",
      Type: "string",
      Default: "-",
      Description: {
        start: "Used to set the RTSnackbar title, its a required prop.",
      },
    },
    {
      id: "dateTime*",
      Type: "string",
      Default: "-",
      Description: {
        start: "Used to set the RTSnackbar date or time, its a required prop.",
      },
    },
    {
      id: "content*",
      Type: "node",
      Default: "-",
      Description: {
        start: "Used to set the RTSnackbar content or description, its a required prop.",
      },
    },
    {
      id: "bgWhite",
      Type: "bool",
      Default: "false",
      Description: {
        start: "Used to set the RTSnackbar background color to white.",
      },
    },
    {
      id: "close*",
      Type: "function",
      Default: "-",
      Description: {
        start:
          "Used to close the RTSnackbar once the user hit the close button, its a required prop.",
      },
    },
    {
      id: "MUI Props",
      Type: "-",
      Default: "-",
      Description: {
        start: "You can pass all of the ",
        link: "MUI Snackbar props",
        end: " for the RTSnackbar as well.",
        url: "https://mui.com/material-ui/api/snackbar/#props",
      },
    },
  ];
  return (
    <>
      {/* <BaseLayout
      // title={t("pageheader")}
      // breadcrumb={[
      //   { label: "Page_Sections", route: "/sections/page-sections/page-headers" },
      //   { label: "pageheader" },
      // ]}
      > */}
      <MKBox
        component="section"
        pt={2}
        pb={12}
        sx={{
          height: 300,
          width: "100%",
          //   [`.${gridClasses.cell}.cold`]: {
          //     backgroundColor: "#b9d5ff91",
          //     color: "#1a3e72",
          //   },
          [`.${gridClasses.cell}.hot`]: {
            // backgroundColor: "#ff943975",
            color: "#1890ff",
          },
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h4" mt={6} mb={3}>
                    RTSnackbar API
                  </MKTypography>
                  <RTTypography variant="h5" mb={3} ml={3}>
                    Demos
                  </RTTypography>
                  {/* <RTAlert color="success" sx={{ backGroundColor: "#6ae79c" }}> */}
                  <RTBox>
                    <NavLink to={"/sections/attention-catchers/notifications"}>
                      <RTButton
                        ml={5}
                        color={"blue"}
                        sx={{ fontSize: 20, color: "#1890ff", textTransform: "none" }}
                      >
                        RTSnackbar Demos
                      </RTButton>
                    </NavLink>
                    {/* <RTLink
                        key={"input"}
                        href={"/sections/input-areas/inputs"}
                        target="_blank"
                        rel="noreferrer"
                        // color={"blue"}
                        //   sx={{ textDecoration: "none" }}
                      >
                        {"> "}RTSnackbar
                      </RTLink> */}
                  </RTBox>
                  {/* </RTAlert> */}
                  <MKTypography variant="h5" mt={3} mb={2} ml={3}>
                    Props Information
                  </MKTypography>
                  <RTBox ml={3}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pagination={false}
                      disableColumnMenu={true}
                      disableColumnFilter={true}
                      disableSelectionOnClick={true}
                      disableRowEdit={true}
                      disableColumnResize={true}
                      // density="compact"
                      hideToolbar={true}
                      hideFooter={true}
                      headerBackgroundColor="#fff"
                      headerTitleColor="#525f7f"
                      // sx={{ border: 1, borderColor: "divider" }}
                      getCellClassName={(params) => {
                        if (params.field === "Type" || params.field === "Default") {
                          return "hot";
                        }
                        // return "hot";
                      }}
                    />
                  </RTBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      {/* </BaseLayout> */}
    </>
  );
}

export default SnackbarAPI;
