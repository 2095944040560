// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/View";

// PageHeaders page components
import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";
import HeaderTwo from "layouts/sections/page-sections/page-headers/components/HeaderTwo";
import HeaderThree from "layouts/sections/page-sections/page-headers/components/HeaderThree";

// PageHeaders page components code
import headerOneCode from "layouts/sections/page-sections/page-headers/components/HeaderOne/code";
import headerTwoCode from "layouts/sections/page-sections/page-headers/components/HeaderTwo/code";
import headerThreeCode from "layouts/sections/page-sections/page-headers/components/HeaderThree/code";
import { t } from "i18next";
function PageHeaders() {
  return (
    <BaseLayout
      title={t("pageheader")}
      breadcrumb={[
        { label: "Page_Sections", route: "/sections/page-sections/page-headers" },
        { label: "pageheader" },
      ]}
    >
      <View title="Header 1" code={headerOneCode} height="40rem">
        <HeaderOne />
      </View>
      <View title="Header 2" code={headerTwoCode} height="40rem">
        <HeaderTwo />
      </View>
      <View title="Header 3" code={headerThreeCode} height="40rem">
        <HeaderThree />
      </View>
    </BaseLayout>
  );
}

export default PageHeaders;
