/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import CoworkingPage from "layouts/pages/landing-pages/coworking";
import Rental from "layouts/pages/landing-pages/rental";
import AboutUs from "layouts/pages/company/about-us";
import Pricing from "layouts/pages/company/pricing";
import HelpCenter from "layouts/pages/support/help-center";
import ContactUs from "layouts/pages/support/contact-us";
import Faq from "layouts/pages/support/faq";
import Privacy from "layouts/pages/support/privacy";
import DesktopApp from "layouts/pages/apps/desktop-app";
import SingleArticle from "layouts/pages/blogs/single-article";
import Author from "layouts/pages/blogs/author";
import VirtualRealityPage from "layouts/pages/extra/virtual-reality";

// Account
import SignInBasicPage from "layouts/authentication/sign-in/basic";
import SignInCoverPage from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignInSimplePage from "layouts/authentication/sign-in/simple";
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import ResetPasswordPage from "layouts/authentication/reset-password/cover";

// Sections
import PageHeaders from "layouts/sections/page-sections/page-headers";
import Features from "layouts/sections/page-sections/featuers";
import PricingSection from "layouts/sections/page-sections/pricing";
import FaqSection from "layouts/sections/page-sections/faq";
import BlogPosts from "layouts/sections/page-sections/blog-posts";
import Testimonials from "layouts/sections/page-sections/testimonials";
import Teams from "layouts/sections/page-sections/teams";
import Stats from "layouts/sections/page-sections/stats";
import Cta from "layouts/sections/page-sections/cta";
import Applications from "layouts/sections/page-sections/applications";
import LogoAreas from "layouts/sections/page-sections/logo-areas";
import Footers from "layouts/sections/page-sections/footers";
import GeneralCards from "layouts/sections/page-sections/general-cards";
import ContentSections from "layouts/sections/page-sections/content-sections";
import Navbars from "layouts/sections/navigation/navbars";
import NavTabs from "layouts/sections/navigation/nav-tabs";
import Pagination from "layouts/sections/navigation/pagination";
import Newsletters from "layouts/sections/input-areas/newsletters";
import ContactSections from "layouts/sections/input-areas/contact-sections";
import Inputs from "layouts/sections/input-areas/inputs";
import Forms from "layouts/sections/input-areas/forms";
import Alerts from "layouts/sections/attention-catchers/alerts";
import Notifications from "layouts/sections/attention-catchers/notifications";
import Modals from "layouts/sections/attention-catchers/modals";
import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
import Avatars from "layouts/sections/elements/avatars";
import Badges from "layouts/sections/elements/badges";
import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
import Buttons from "layouts/sections/elements/buttons";
import Dropdowns from "layouts/sections/elements/dropdowns";
import ProgressBars from "layouts/sections/elements/progress-bars";
import SocialButtons from "layouts/sections/elements/social-buttons";
import Tables from "layouts/sections/elements/tables";
import Toggles from "layouts/sections/elements/toggles";
import Typography from "layouts/sections/elements/typography";
import InputAPI from "pages/Api/Input";
import AlterAPI from "pages/Api/Alter";
import BoxAPI from "pages/Api/Box";
import ButtonAPI from "pages/Api/Button";
import SnackbarAPI from "pages/Api/Snackbar";
import TypographyAPI from "pages/Api/Typography";
import GetStart from "pages/start";
import AppBarAPI from "pages/Api/Appbar";
import TableAPI from "pages/Api/Table";

const routes = [
  // {
  //   name: "pages",
  //   icon: <Icon>dashboard</Icon>,
  //   collapse: [
  //     {
  //       name: "company",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "about_us",
  //           route: "/pages/company/about-us",
  //           component: <AboutUs />,
  //         },
  //         {
  //           name: "pricing",
  //           route: "/pages/company/pricing",
  //           component: <Pricing />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "extra",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "single_article",
  //           route: "/pages/blogs/single-article",
  //           component: <SingleArticle />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "support",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "contact_us",
  //           route: "/pages/support/contact-us",
  //           component: <ContactUs />,
  //         },
  //         {
  //           name: "privacy",
  //           route: "/pages/support/privacy",
  //           component: <Privacy />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "title",
  //   title: "Getting Started",
  //   key: "title-pages",
  //   name: "title-pages",
  // },
  {
    type: "collapse",
    name: "Getting Started",
    key: "gettingstarted",
    route: "/getstart",
    component: <GetStart />,
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  },
  {
    type: "title",
    title: "Components",
    key: "Components",
    name: "Components",
  },
  {
    type: "collapse",
    name: "Tables",
    route: "/sections/elements/tables",
    component: <Tables />,
    icon: <Icon fontSize="medium">table</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Inputs",
    route: "/sections/input-areas/inputs",
    component: <Inputs />,
    icon: <Icon fontSize="medium">input</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Alerts",
    route: "/sections/attention-catchers/alerts",
    component: <Alerts />,
    icon: <Icon fontSize="medium">warning</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Notifications",
    route: "/sections/attention-catchers/notifications",
    component: <Notifications />,
    icon: <Icon fontSize="medium">notifications</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Modals",
    route: "/sections/attention-catchers/modals",
    component: <Modals />,
    icon: <Icon fontSize="medium">airplay</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Buttons",
    route: "/sections/elements/buttons",
    component: <Buttons />,
    icon: <Icon fontSize="medium">crop169</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Dropdowns",
    route: "/sections/elements/dropdowns",
    component: <Dropdowns />,
    icon: <Icon fontSize="medium">menuopen</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Typography",
    route: "/sections/elements/typography",
    component: <Typography />,
    icon: <Icon fontSize="medium">assignmentsharp</Icon>,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Forms",
  //   route: "/sections/input-areas/forms",
  //   component: <Forms />,
  //   icon: <Icon fontSize="medium">list</Icon>,
  //   noCollapse: true,
  // },
  {
    type: "title",
    title: "Component API",
    key: "Component API",
    name: "Component API",
  },
  {
    name: "API",
    type: "collapse",
    icon: <Icon>api</Icon>,
    // dropdown: true,
    collapse: [
      {
        name: "RTTable",
        route: "/sections/tableapi",
        component: <TableAPI />,
      },
      {
        name: "RTInput",
        route: "/sections/inputapi",
        component: <InputAPI />,
      },
      {
        name: "RTAlert",
        route: "/sections/alterapi",
        component: <AlterAPI />,
      },
      {
        name: "RTBox",
        route: "/sections/boxapi",
        component: <BoxAPI />,
      },
      {
        name: "RTButton",
        route: "/sections/buttonapi",
        component: <ButtonAPI />,
      },
      {
        name: "RTSnackbar",
        route: "/sections/snackbarapi",
        component: <SnackbarAPI />,
      },
      {
        name: "RTTypography",
        route: "/sections/typographyapi",
        component: <TypographyAPI />,
      },
      {
        name: "RTAppBar",
        route: "/sections/appbarapi",
        component: <AppBarAPI />,
      },
    ],
  },
  {
    type: "title",
    title: "Examples",
    key: "Examples",
    name: "Examples",
  },
  {
    name: "Page_Sections",
    description: "routesdes1",
    type: "collapse",
    icon: <Icon>view_day</Icon>,
    // dropdown: true,
    collapse: [
      {
        name: "Forms",
        route: "/sections/input-areas/forms",
        component: <Forms />,
      },
      {
        name: "Page_headers",
        route: "/sections/page-sections/page-headers",
        component: <PageHeaders />,
      },
      {
        name: "Stats",
        route: "/sections/page-sections/stats",
        component: <Stats />,
      },
      {
        name: "General_cards",
        route: "/sections/page-sections/general-cards",
        component: <GeneralCards />,
      },
      {
        name: "Features",
        route: "/sections/page-sections/features",
        component: <Features />,
      },
      {
        name: "Pricing",
        route: "/sections/page-sections/pricing",
        component: <PricingSection />,
      },
      {
        name: "FAQ",
        route: "/sections/page-sections/faq",
        component: <FaqSection />,
      },
      // {
      //   name: "blog_posts",
      //   route: "/sections/page-sections/blog-posts",
      //   component: <BlogPosts />,
      // },
      // {
      //   name: "call_to_actions",
      //   route: "/sections/page-sections/cta",
      //   component: <Cta />,
      // },
      {
        name: "Footers",
        route: "/sections/page-sections/footers",
        component: <Footers />,
      },
      // {
      //   name: "content_sections",
      //   route: "/sections/page-sections/content-sections",
      //   component: <ContentSections />,
      // },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "sections",
  //   icon: <Icon>view_day</Icon>,
  //   collapse: [
  //     {
  //       name: "Page_Sections",
  //       description: "routesdes1",
  //       type: "collapse",
  //       collapse: [
  //         {
  //           name: "page_headers",
  //           route: "/sections/page-sections/page-headers",
  //           component: <PageHeaders />,
  //         },
  //         {
  //           name: "features",
  //           route: "/sections/page-sections/features",
  //           component: <Features />,
  //         },
  //         {
  //           name: "pricing",
  //           route: "/sections/page-sections/pricing",
  //           component: <PricingSection />,
  //         },
  //         {
  //           name: "FAQ",
  //           route: "/sections/page-sections/faq",
  //           component: <FaqSection />,
  //         },
  //         {
  //           name: "blog_posts",
  //           route: "/sections/page-sections/blog-posts",
  //           component: <BlogPosts />,
  //         },
  //         {
  //           name: "stats",
  //           route: "/sections/page-sections/stats",
  //           component: <Stats />,
  //         },
  //         {
  //           name: "call_to_actions",
  //           route: "/sections/page-sections/cta",
  //           component: <Cta />,
  //         },
  //         {
  //           name: "footers",
  //           route: "/sections/page-sections/footers",
  //           component: <Footers />,
  //         },
  //         {
  //           name: "general_cards",
  //           route: "/sections/page-sections/general-cards",
  //           component: <GeneralCards />,
  //         },
  //         {
  //           name: "content_sections",
  //           route: "/sections/page-sections/content-sections",
  //           component: <ContentSections />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Navigation",
  //       description: "routesdes2",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "Navbars",
  //           route: "/sections/navigation/navbars",
  //           component: <Navbars />,
  //         },
  //         {
  //           name: "Nav_Tabs",
  //           route: "/sections/navigation/nav-tabs",
  //           component: <NavTabs />,
  //         },
  //         // {
  //         //   name: "Pagination",
  //         //   route: "/sections/navigation/pagination",
  //         //   component: <Pagination />,
  //         // },
  //       ],
  //     },
  //     {
  //       name: "Input_Areas",
  //       description: "routesdes3",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "Newsletters",
  //           route: "/sections/input-areas/newsletters",
  //           component: <Newsletters />,
  //         },
  //         {
  //           name: "Contact_Sections",
  //           route: "/sections/input-areas/contact-sections",
  //           component: <ContactSections />,
  //         },
  //         {
  //           name: "Inputs",
  //           route: "/sections/input-areas/inputs",
  //           component: <Inputs />,
  //         },
  //         {
  //           name: "Forms",
  //           route: "/sections/input-areas/forms",
  //           component: <Forms />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Attention_Catchers",
  //       description: "routesdes4",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "Alerts",
  //           route: "/sections/attention-catchers/alerts",
  //           component: <Alerts />,
  //         },
  //         {
  //           name: "Notifications",
  //           route: "/sections/attention-catchers/notifications",
  //           component: <Notifications />,
  //         },
  //         {
  //           name: "Modals",
  //           route: "/sections/attention-catchers/modals",
  //           component: <Modals />,
  //         },
  //         {
  //           name: "Tooltips_Popovers",
  //           route: "/sections/attention-catchers/tooltips-popovers",
  //           component: <TooltipsPopovers />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Elements",
  //       description: "routesdes5",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "Breadcrumbs",
  //           route: "/sections/elements/breadcrumbs",
  //           component: <BreadcrumbsEl />,
  //         },
  //         {
  //           name: "Buttons",
  //           route: "/sections/elements/buttons",
  //           component: <Buttons />,
  //         },
  //         {
  //           name: "Dropdowns",
  //           route: "/sections/elements/dropdowns",
  //           component: <Dropdowns />,
  //         },
  //         {
  //           name: "Tables",
  //           route: "/sections/elements/tables",
  //           component: <Tables />,
  //         },
  //         {
  //           name: "Typography",
  //           route: "/sections/elements/typography",
  //           component: <Typography />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "sections",
  //   icon: <Icon>view_day</Icon>,
  //   collapse: [
  //     {
  //       name: "Page_Sections",
  //       description: "routesdes1",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "page_headers",
  //           route: "/sections/page-sections/page-headers",
  //           component: <PageHeaders />,
  //         },
  //         {
  //           name: "features",
  //           route: "/sections/page-sections/features",
  //           component: <Features />,
  //         },
  //         {
  //           name: "pricing",
  //           route: "/sections/page-sections/pricing",
  //           component: <PricingSection />,
  //         },
  //         {
  //           name: "FAQ",
  //           route: "/sections/page-sections/faq",
  //           component: <FaqSection />,
  //         },
  //         {
  //           name: "blog_posts",
  //           route: "/sections/page-sections/blog-posts",
  //           component: <BlogPosts />,
  //         },
  //         {
  //           name: "stats",
  //           route: "/sections/page-sections/stats",
  //           component: <Stats />,
  //         },
  //         {
  //           name: "call_to_actions",
  //           route: "/sections/page-sections/cta",
  //           component: <Cta />,
  //         },
  //         {
  //           name: "footers",
  //           route: "/sections/page-sections/footers",
  //           component: <Footers />,
  //         },
  //         {
  //           name: "general_cards",
  //           route: "/sections/page-sections/general-cards",
  //           component: <GeneralCards />,
  //         },
  //         {
  //           name: "content_sections",
  //           route: "/sections/page-sections/content-sections",
  //           component: <ContentSections />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Navigation",
  //       description: "routesdes2",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "Navbars",
  //           route: "/sections/navigation/navbars",
  //           component: <Navbars />,
  //         },
  //         {
  //           name: "Nav_Tabs",
  //           route: "/sections/navigation/nav-tabs",
  //           component: <NavTabs />,
  //         },
  //         // {
  //         //   name: "Pagination",
  //         //   route: "/sections/navigation/pagination",
  //         //   component: <Pagination />,
  //         // },
  //       ],
  //     },
  //     {
  //       name: "Input_Areas",
  //       description: "routesdes3",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "Newsletters",
  //           route: "/sections/input-areas/newsletters",
  //           component: <Newsletters />,
  //         },
  //         {
  //           name: "Contact_Sections",
  //           route: "/sections/input-areas/contact-sections",
  //           component: <ContactSections />,
  //         },
  //         {
  //           name: "Inputs",
  //           route: "/sections/input-areas/inputs",
  //           component: <Inputs />,
  //         },
  //         {
  //           name: "Forms",
  //           route: "/sections/input-areas/forms",
  //           component: <Forms />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Attention_Catchers",
  //       description: "routesdes4",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "Alerts",
  //           route: "/sections/attention-catchers/alerts",
  //           component: <Alerts />,
  //         },
  //         {
  //           name: "Notifications",
  //           route: "/sections/attention-catchers/notifications",
  //           component: <Notifications />,
  //         },
  //         {
  //           name: "Modals",
  //           route: "/sections/attention-catchers/modals",
  //           component: <Modals />,
  //         },
  //         {
  //           name: "Tooltips_Popovers",
  //           route: "/sections/attention-catchers/tooltips-popovers",
  //           component: <TooltipsPopovers />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Elements",
  //       description: "routesdes5",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "Breadcrumbs",
  //           route: "/sections/elements/breadcrumbs",
  //           component: <BreadcrumbsEl />,
  //         },
  //         {
  //           name: "Buttons",
  //           route: "/sections/elements/buttons",
  //           component: <Buttons />,
  //         },
  //         {
  //           name: "Dropdowns",
  //           route: "/sections/elements/dropdowns",
  //           component: <Dropdowns />,
  //         },
  //         {
  //           name: "Tables",
  //           route: "/sections/elements/tables",
  //           component: <Tables />,
  //         },
  //         {
  //           name: "Typography",
  //           route: "/sections/elements/typography",
  //           component: <Typography />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
