import { useState } from "react";

import { RTContainer, RTGrid, RTModal, RTDivider, RTSlide, RTIcon } from "rt-serverless-ui";

import { RTBox, RTButton, RTTypography } from "rt-serverless-ui";

function NotificationRTModal() {
  const [show, setShow] = useState(false);
  const toggleRTModal = () => setShow(!show);

  return (
    <RTBox component="section" py={6}>
      <RTContainer>
        <RTGrid container item xs={12} lg={10} justifyContent="center" mx="auto">
          <RTButton variant="gradient" color="error" onClick={toggleRTModal}>
            Launch Demo RTModal
          </RTButton>
        </RTGrid>
        <RTModal open={show} onClose={toggleRTModal} sx={{ display: "grid", placeItems: "center" }}>
          <RTSlide direction="down" in={show} timeout={500}>
            <RTBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              variant="gradient"
              bgColor="error"
              shadow="sm"
            >
              <RTBox
                display="flex"
                alginItems="center"
                justifyContent="space-between"
                py={3}
                px={2}
              >
                <RTTypography variant="h6" color="white">
                  Your attention is required
                </RTTypography>
                <RTIcon
                  color="white"
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={toggleRTModal}
                >
                  close
                </RTIcon>
              </RTBox>
              <RTDivider light sx={{ my: 0 }} />
              <RTBox p={6} textAlign="center" color="white">
                <RTIcon fontSize="large" color="inherit">
                  notifications_active
                </RTIcon>
                <RTTypography variant="h4" color="white" mt={3} mb={1}>
                  You should read this!
                </RTTypography>
                <RTTypography variant="body2" color="white" opacity={0.8} mb={2}>
                  A small river named Duden flows by their place and supplies it with the necessary
                  regelialia.
                </RTTypography>
              </RTBox>
              <RTDivider light sx={{ my: 0 }} />
              <RTBox display="flex" justifyContent="space-between" py={2} px={1.5}>
                <RTButton color="white">ok, got it</RTButton>
                <RTButton variant="text" color="white" onClick={toggleRTModal}>
                  close
                </RTButton>
              </RTBox>
            </RTBox>
          </RTSlide>
        </RTModal>
      </RTContainer>
    </RTBox>
  );
}

export default NotificationRTModal;
