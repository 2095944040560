import CenteredFooter from "examples/Footers/CenteredFooter";

function FooterThress() {
  const company = { href: "/", name: " " };
  const links = [
    { href: "/", name: "Company" },
    { href: "/presentation", name: "About Us" },
    { href: "/presentation", name: "Team" },
    { href: "/templates/react", name: "Products" },
    { href: "/blog", name: "Blog" },
    { href: "/license", name: "License" },
  ];
  const socials = [
    { icon: <i className="fab fa-facebook" />, link: "https://www.facebook.com//" },
    {
      icon: <i className="fab fa-twitter" />,
      link: "https://twitter.com/",
    },
    {
      icon: <i className="fab fa-instagram" />,
      link: "https://www.instagram.com/official/",
    },
    {
      icon: <i className="fab fa-pinterest" />,
      link: "https://ro.pinterest.com/the/",
    },
    { icon: <i className="fab fa-github" />, link: "https://github.com/official" },
  ];

  return <CenteredFooter company={company} links={links} socials={socials} />;
}

export default FooterThress;
