const statsTwoCode = `
import { RTContainer, RTGrid } from "rt-serverless-ui";
import { RTBox } from "rt-serverless-ui";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function StatsTwo() {
  return (
    <RTBox component="section" py={3}>
      <RTContainer>
        <RTGrid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <RTGrid item xs={12} md={4}>
            <DefaultCounterCard
              color="primary"
              count={323}
              title="Projects"
              description="Of “high-performing” level are led by a certified project manager"
            />
          </RTGrid>
          <RTGrid item xs={12} md={4} display="flex">
            <DefaultCounterCard
              color="primary"
              count={500}
              suffix="+"
              title="Hours"
              description="That meets quality standards required by our users"
            />
          </RTGrid>
          <RTGrid item xs={12} md={4}>
            <DefaultCounterCard
              color="primary"
              count={24}
              suffix="/7"
              title="Support"
              description="Actively engage team members that finishes on time"
            />
          </RTGrid>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default StatsTwo;`;

export default statsTwoCode;
