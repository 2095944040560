import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  RTAlert,
  RTBox,
  RTTypography,
  RTDivider,
  RTLink,
  RTTable,
  RTButton,
} from "rt-serverless-ui";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useLocation, NavLink } from "react-router-dom";
// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import BaseLayout from "layouts/sections/components/BaseLayout";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import buttonsContainedCode from "layouts/sections/elements/buttons/components/ButtonsContained/code";
import View from "layouts/sections/components/View";
import ButtonsContained from "layouts/sections/elements/buttons/components/ButtonsContained";
import buttonsGradientCode from "layouts/sections/elements/buttons/components/ButtonsGradient/code";
import ButtonsGradient from "layouts/sections/elements/buttons/components/ButtonsGradient";
import featuresThreeCode from "layouts/sections/page-sections/featuers/components/FeaturesThree/code";
import FeaturesThree from "layouts/sections/page-sections/featuers/components/FeaturesThree";
import tableFourCode from "layouts/sections/elements/tables/components/TableFour/code";
import TableFour from "layouts/sections/elements/tables/components/TableFour";

function GetStart() {
  return (
    <>
      {/* <BaseLayout
      // title={t("pageheader")}
      // breadcrumb={[
      //   { label: "Page_Sections", route: "/sections/page-sections/page-headers" },
      //   { label: "pageheader" },
      // ]}
      > */}
      <MKBox
        component="section"
        pt={2}
        pb={12}
        sx={{
          height: 300,
          width: "100%",
          //   [`.${gridClasses.cell}.cold`]: {
          //     backgroundColor: "#b9d5ff91",
          //     color: "#1a3e72",
          //   },
          [`.${gridClasses.cell}.hot`]: {
            // backgroundColor: "#ff943975",
            color: "#1890ff",
          },
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox pb={6} px={6}>
                  {/* <MKTypography variant="h4" mt={6} mb={3}>
                    Getting Started
                  </MKTypography> */}
                  <RTTypography variant="h5" mb={3} ml={3} mt={3}>
                    ローカル開発
                  </RTTypography>
                  {/* <RTAlert color="success" sx={{ backGroundColor: "#6ae79c" }}> */}
                  <RTBox>
                    <RTTypography
                      ml={5}
                      //   color={"blue"}
                      sx={{ fontSize: 20, textTransform: "none" }}
                    >
                      {/* 本地开发
                        · 从 NodeJS 官方页面安装 NodeJS LTS最新版本

                        · 在终端中运行 npx create-react-app my-app 或 yarn create react-app my-app

                        · 运行 npm i rt-serverless-ui 或 yarn add rt-serverless-ui

                        使用表格组件进行页面开发 */}
                      {/* NodeJSの公式ページから最新版のNodeJS LTSをインストールする */}
                      <b>·</b>
                      <NavLink to={"https://nodejs.org/en/"} target="_blank">
                        <b style={{ color: "#1890ff" }}>NodeJSの公式ページか</b>
                      </NavLink>
                      から最新版のNodeJS LTSをインストールする
                    </RTTypography>
                    <RTTypography ml={5}>
                      {/* 端末でnpx create-react-app my-appまたはyarn create react-app my-appを実行する */}
                      <b>·</b> 端末で <b>npx create-react-app my-app</b> または{" "}
                      <b>yarn create react-app my-app</b>を実行する
                    </RTTypography>
                    <RTTypography ml={5}>
                      {/* npm i rt-serverless-uiまたはyarn add rt-serverless-uiを実行 */}
                      <b>·</b>
                      <b>npm i rt-serverless-ui</b> または <b>yarn add rt-serverless-ui</b>を実行
                    </RTTypography>
                    <RTTypography ml={5} mt={2}>
                      テーブルポーネントを使用したページ開発
                      {/* <View code={buttonsGradientCode}>
                        <ButtonsGradient />
                      </View> */}
                      <View code={tableFourCode}>
                        <TableFour />
                      </View>
                    </RTTypography>
                  </RTBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      {/* </BaseLayout> */}
    </>
  );
}

export default GetStart;
