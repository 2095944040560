// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  RTAlert,
  RTBox,
  RTTypography,
  RTDivider,
  RTLink,
  RTTable,
  RTButton,
} from "rt-serverless-ui";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useLocation, NavLink } from "react-router-dom";
// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

function BoxAPI() {
  const columns = [
    { field: "id", headerName: "Name", width: 150, sortable: false },
    {
      field: "Type",
      headerName: "Type",
      width: 130,
      sortable: false,
    },
    {
      field: "Default",
      headerName: "Default",
      width: 130,
      sortable: false,
    },
    {
      field: "Description",
      headerName: "Description",
      disableColumnMenu: true,
      width: 500,
      sortable: false,
      renderCell: (params) => (
        <p>
          {params.value.start}
          <a href={params.value.url} target="_blank" rel="noopener" style={{ color: "#1890ff" }}>
            {params.value.link}
          </a>
          {params.value.end}
        </p>
      ),
    },
  ];

  const rows = [
    {
      id: "bgColor",
      Type: "string",
      Default: "transparent",
      Description: {
        start: "Change the RTBox background color.",
      },
    },
    {
      id: "color",
      Type: "string",
      Default: "dark",
      Description: {
        start: "Change the RTBox text color.",
      },
    },
    {
      id: "variant",
      Type: "'gradient''contained'",
      Default: "contained",
      Description: {
        start: "Change the RTBox background type.",
      },
    },
    {
      id: "opacity",
      Type: "number",
      Default: "1",
      Description: {
        start: "Change the RTBox opacity.",
      },
    },
    {
      id: "borderRadius",
      Type: "string",
      Default: "none",
      Description: {
        start: "Set a border radius for the RTBox.",
      },
    },
    {
      id: "shadow",
      Type: "string",
      Default: "none",
      Description: {
        start: "Set a box shadow for the RTBox.",
      },
    },
    {
      id: "coloredShadow",
      Type: `
      'primary'
      'secondary'
      'info'
      'success'
      'warning'
      'error'
      'light'
      'dark'`,
      Default: "none",
      Description: {
        start: "Set a colorful box shadow for the RTBox.",
      },
    },
    {
      id: "opacity",
      Type: "number",
      Default: "1",
      Description: {
        start: "Change the RTBox opacity.",
      },
    },
    {
      id: "MUI Props",
      Type: "-",
      Default: "-",
      // Description: "You can pass all of the MUI Box props for the RTBox as well.",
      Description: {
        start: "You can pass all of the ",
        link: "MUI Box props",
        end: " for the RTBox as well.",
        url: "https://mui.com/material-ui/api/box/#props",
      },
    },
  ];
  return (
    <>
      <MKBox
        component="section"
        pt={2}
        pb={12}
        sx={{
          height: 300,
          width: "100%",
          //   [`.${gridClasses.cell}.cold`]: {
          //     backgroundColor: "#b9d5ff91",
          //     color: "#1a3e72",
          //   },
          [`.${gridClasses.cell}.hot`]: {
            // backgroundColor: "#ff943975",
            color: "#1890ff",
          },
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h4" mt={6} mb={3}>
                    RTBox API
                  </MKTypography>
                  <RTTypography variant="h5" mb={3} ml={3}>
                    Demos
                  </RTTypography>
                  {/* <RTAlert color="success" sx={{ backGroundColor: "#6ae79c" }}> */}
                  <RTBox>
                    <NavLink to={"/sections/page-sections/general-cards"}>
                      <RTButton
                        ml={5}
                        color={"blue"}
                        sx={{ fontSize: 20, color: "#1890ff", textTransform: "none" }}
                      >
                        RTBox Demos
                      </RTButton>
                    </NavLink>
                    {/* <RTLink
                        key={"input"}
                        href={"/sections/input-areas/inputs"}
                        target="_blank"
                        rel="noreferrer"
                        // color={"blue"}
                        //   sx={{ textDecoration: "none" }}
                      >
                        {"> "}RTBox
                      </RTLink> */}
                  </RTBox>
                  {/* </RTAlert> */}
                  <MKTypography variant="h5" mt={3} mb={2} ml={3}>
                    Props Information
                  </MKTypography>
                  <RTBox ml={3}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pagination={false}
                      disableColumnMenu={true}
                      disableColumnFilter={true}
                      disableSelectionOnClick={true}
                      disableRowEdit={true}
                      disableColumnResize={true}
                      // density="compact"
                      hideToolbar={true}
                      hideFooter={true}
                      headerBackgroundColor="#fff"
                      headerTitleColor="#525f7f"
                      // sx={{ border: 1, borderColor: "divider" }}
                      getCellClassName={(params) => {
                        if (params.field === "Type" || params.field === "Default") {
                          return "hot";
                        }
                        // return "hot";
                      }}
                    />
                  </RTBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default BoxAPI;
