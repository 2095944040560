// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  RTAlert,
  RTBox,
  RTTypography,
  RTDivider,
  RTLink,
  RTTable,
  RTButton,
} from "rt-serverless-ui";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useLocation, NavLink } from "react-router-dom";
// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";

function AlterAPI() {
  //   const useStyles = makeStyles({
  //     cell: {
  //       whiteSpace: "normal",
  //       wordWrap: "break-word",
  //       lineHeight: "1.5em",
  //       height: "auto !important",
  //       display: "flex",
  //       alignItems: "center",
  //     },
  //   });
  //   const classes = useStyles();
  const columns = [
    { field: "id", headerName: "Name", width: 150, sortable: false },
    {
      field: "Type",
      headerName: "Type",
      width: 130,
      sortable: false,
      // renderCell: (params) => <span style={{ color: "#1890ff" }}>{params.value}</span>,
      // renderCell: (params) => (
      //   <ul>
      //     {params.value.map((val) => (
      //       <li>{val}</li>
      //     ))}
      //   </ul>
      // ),
    },
    {
      field: "Default",
      headerName: "Default",
      width: 130,
      sortable: false,
      //   renderCell: (params) => <div style={{ color: "#1890ff" }}>{params.value}</div>,
    },
    {
      field: "Description",
      headerName: "Description",
      disableColumnMenu: true,
      width: 600,
      sortable: false,
    },
  ];

  const rows = [
    {
      id: "color",
      Type: `
      'primary'
      'secondary'
      'info'
      'success'
      'warning'
      'error'
      'light'
      'dark'`,
      Default: "info",
      Description: "Change the RTAlert background color.",
    },
    {
      id: "dismissible",
      Type: "bool",
      Default: "false",
      Description: "If true the RTAlert will have a closing button for dismissing it.",
    },
    {
      id: "children*",
      Type: "node",
      Default: "-",
      Description: "Use to pass node or content inside the RTAlert, its the only required prop.",
    },
  ];
  return (
    <>
      <MKBox
        component="section"
        pt={2}
        pb={12}
        sx={{
          height: 300,
          width: "100%",
          //   [`.${gridClasses.cell}.cold`]: {
          //     backgroundColor: "#b9d5ff91",
          //     color: "#1a3e72",
          //   },
          [`.${gridClasses.cell}.hot`]: {
            // backgroundColor: "#ff943975",
            color: "#1890ff",
          },
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h4" mt={6} mb={3}>
                    RTAlter API
                  </MKTypography>
                  <RTTypography variant="h5" mb={3} ml={3}>
                    Demos
                  </RTTypography>
                  {/* <RTAlert color="success" sx={{ backGroundColor: "#6ae79c" }}> */}
                  <RTBox>
                    <NavLink to={"/sections/attention-catchers/alerts"}>
                      <RTButton
                        ml={5}
                        color={"blue"}
                        sx={{ fontSize: 20, color: "#1890ff", textTransform: "none" }}
                      >
                        RTAlter Demos
                      </RTButton>
                    </NavLink>
                  </RTBox>
                  {/* </RTAlert> */}
                  <MKTypography variant="h5" mt={3} mb={2} ml={3}>
                    Props Information
                  </MKTypography>
                  <RTBox ml={3}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pagination={false}
                      disableColumnMenu={true}
                      disableColumnFilter={true}
                      disableSelectionOnClick={true}
                      disableRowEdit={true}
                      disableColumnResize={true}
                      // density="compact"
                      hideToolbar={true}
                      hideFooter={true}
                      headerBackgroundColor="#fff"
                      headerTitleColor="#525f7f"
                      // sx={{ border: 1, borderColor: "divider" }}
                      //   componentsProps={{
                      //     cell: {
                      //       className: classes.cell,
                      //     },
                      //   }}
                      getCellClassName={(params) => {
                        if (params.field === "Type" || params.field === "Default") {
                          return "hot";
                        }
                        // return "hot";
                      }}
                    />
                  </RTBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AlterAPI;
