export default {
  lange: "En",
  welcome: "Serverless Web Pro へようこそ",
  create_account: "アカウントの作成",
  counttitle1: "コーディング要素",
  countdes1: "ボタンから入力、ナビゲーションバー、アラート、カードまで参加できます",
  counttitle2: "デザインブロック",
  countdes2: "セクションを混ぜて、色を変えて、あなたの創造性を解き放つ",
  counttitle3: "ページ",
  countdes3:
    "Webサイトのために事前に作成したページを使用すると、3～4週間の作業を省くことができます",
  mkt1: "セクションコレクション",
  mkt2: "ピクセルのように完璧なページに組み合わせたり、カスタマイズしたりできるように、複数のオプションを用意しました。",
  designblocksdes1: "どのような組み合わせにも完璧にフィットする15ページのセクションのセレクション",
  pageheader: "ヘッダー",
  features: "機能",
  pricing: "価格",
  FAQ: "質問",
  blog_posts: "ブログ記事",
  stats: "統計データ",
  call_to_actions: "行動呼びかけ",
  footers: "フッター",
  general_cards: "共通カード",
  content_sections: "コンテンツセクション",
  Navigation: "こうほう",
  designblocksdes2: "ページを参照するのに役立つ20以上のコンポーネント",
  Navbars: "ナビゲーションバー",
  Nav_Tabs: "ナビゲーションタブ",
  Pagination: "ページ番号",
  Input_Areas: "入力領域",
  designblocksdes3: "テキスト操作と挿入に必要な30以上の要素",
  Newsletters: "時事通信",
  Contact_Sections: "連絡先セクション",
  Inputs: "入力",
  Forms: "フォーム",
  Attention_Catchers: "注意事項",
  designblocksdes4:
    "画面の異なる位置からポップアップする20以上の完全にエンコードされたコンポーネント",
  Alerts: "アラート",
  Notifications: "通知",
  Modals: "モーダル",
  Tooltips_Popovers: "ポップアップ",
  Elements: "要素",
  designblocksdes5: "30種類以上の丁寧に作られた小さな要素、複数の色と形状",
  Breadcrumbs: "パンくず",
  Buttons: "ボタン",
  Dropdowns: "ドロップダウンリスト",
  Tables: "表",
  Typography: "レイアウト",
  pages: "ページ",
  company: "会社",
  about_us: "私たちについて",
  extra: "エクストラ ",
  single_article: "単品",
  support: "サポート",
  contact_us: "連絡先",
  privacy: "プライバシー",
  account: "アカウント",
  sign_in: "ログイン",
  sign_up: "登録",
  reset_password: "パスワードをリセット",
  sections: "セクション",
  Page_Sections: "ページセクション",
  page_headers: "ヘッダー",
  routesdes1: "すべての25節を参照",
  routesdes2: "すべての3つのナビゲーションを表示",
  routesdes3: "すべて8のつの入力領域を表示",
  routesdes4: "すべての5つの例を表示",
  routesdes5: "すべての12の例を表示",
  sign_des: "ログインするメールとパスワードを入力してください",
  Email: "メール",
  Password: "パスワード",
  Remember_me: "自動ログイン",
  forgetpassword: "パスワードをお忘れですか？",
  nocount: "アカウントはありませんか？",
  signupdes: "登録のためのメールとパスワードの入力",
  term: "契約条件",
  agree: "に同意します",
  alredycount: "もう口座はありますか。",
  name: "名前",
  resetpassword: "パスワードをリセット",
  reset: "送 信",
  Home: "ホーム",
  Preview: "プレビュー",
  Code: "コード",
  login: {
    confirm: "登录",
    reset: "重置",
  },

  tabs: {
    more: "更多",
    closeCurrent: "关闭当前",
    closeOther: "关闭其它",
    closeAll: "关闭所有",
  },
  header: {
    componentSize: "组件大小",
    language: "语言",
    theme: "主题",
    themeSetting: "主题设置",
    darkMode: "暗黑模式",
    lightMode: "浅色模式",
    fullScreen: "全屏",
    exitFullScreen: "退出全屏",
    personalData: "个人资料",
    changePassword: "修改密码",
    logout: "退出登录",
  },
};
