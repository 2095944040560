const tableThreeCode = `import { RTTablePagination } from "rt-serverless-ui";
import { useMovieData } from "./useMovieData";
import React from "react";
export default function TableThree() {
  const VISIBLE_FIELDS = ["title", "company", "director", "year", "cinematicUniverse"];

  const data = useMovieData();
  const columns = React.useMemo(
    () => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [data.columns]
  );
  return (
    <div>
      <RTTablePagination
        rows={data.rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              page: 1,
              pageSize: 3,
            },
          },
        }}
        pageSizeOptions={[3, 6]}
      />
    </div>
  );
}`;

export default tableThreeCode;
