const featuresOneCode = `
import { RTContainer, RTStack, RTGrid, RTIcon } from "rt-serverless-ui";
import { RTBox, RTTypography } from "rt-serverless-ui";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function FeaturesOne() {
  return (
    <RTBox component="section" py={{ xs: 3, md: 12 }}>
      <RTContainer>
        <RTGrid container alignItems="center">
          <RTGrid item xs={12} lg={5}>
            <RTTypography variant="h3" my={1}>
              Read More About Us
            </RTTypography>
            <RTTypography variant="body2" color="text" mb={2}>
              Pain is what we go through as we become older. We get insulted by others, lose trust
              for those others. We get back stabbed by friends. It becomes harder for us to give
              others a hand.
            </RTTypography>
            <RTTypography
              component="a"
              href="#"
              variant="body2"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              More about us
              <RTIcon sx={{ fontWeight: "bold" }}>arrow_forward</RTIcon>
            </RTTypography>
          </RTGrid>
          <RTGrid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <RTStack>
              <AboutUsOption
                icon="mediation"
                content={
                  <>
                    It becomes harder for us to give others a hand.
                    <br />
                    We get our heart broken by people we love.
                  </>
                }
              />
              <AboutUsOption
                icon="settings_overscan"
                content={
                  <>
                    As we live, our hearts turn colder.
                    <br />
                    Cause pain is what we go through as we become older.
                  </>
                }
              />
              <AboutUsOption
                icon="token"
                content={
                  <>
                    When we lose family over time.
                    <br />
                    What else could rust the heart more over time? Blackgold.
                  </>
                }
              />
            </RTStack>
          </RTGrid>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default FeaturesOne;`;

export default featuresOneCode;
