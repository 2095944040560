/* eslint-disable no-template-curly-in-string */
const bgImage =
  "`${linearGradient(rgba(gradients.dark.main, 0.4), rgba(gradients.dark.state, 0.4))}, url(${bgImage})`";

const headerThreeCode = `
import { RTContainer, RTStack, RTLink, RTGrid } from "rt-serverless-ui";
import { RTBox, RTButton, RTTypography } from "rt-serverless-ui";

// Images
import bgImage from "assets/images/bg-presentation.jpg";

function HeaderThree() {
  return (
    <RTBox component="header" position="relative" height="100%">
      <RTBox component="nav" position="absolute" top="0.5rem" width="100%">
        <RTContainer>
          <RTGrid container flexDirection="row" alignItems="center">
            <RTTypography
              component={RTLink}
              href="#"
              variant="button"
              color="white"
              fontWeight="regular"
              py={0.8125}
              mr={2}
            >
              Material Design
            </RTTypography>
            <RTButton
              variant="outlined"
              color="white"
              sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
            >
              <RTBox component="i" color="white" className="fas fa-bars" />
            </RTButton>
            <RTBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  Home
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  About Us
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  Contact Us
                </RTTypography>
              </RTBox>
            </RTBox>
            <RTButton color="default">Sign in</RTButton>
          </RTGrid>
        </RTContainer>
      </RTBox>
      <RTBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>${bgImage},
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <RTContainer>
          <RTGrid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <RTTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mb={3}
            >
              Work with an amazing
            </RTTypography>
            <RTTypography variant="body1" color="white" mt={1} mb={{ xs: 3, sm: 8 }} px={3}>
              We&apos;re constantly trying to express ourselves and actualize our dreams. If you
              have the opportunity to play this game. If you have the opportunity to play this game.
            </RTTypography>
            <RTTypography variant="h6" color="white" textTransform="uppercase" mb={3}>
              connect with us on:
            </RTTypography>
            <RTStack direction="row" spacing={6} mx="auto">
              <RTTypography
                component={RTLink}
                href="#"
                variant="body2"
                onClick={(e) => e.preventDefault()}
              >
                <RTBox component="i" color="white" className="fab fa-facebook" />
              </RTTypography>
              <RTTypography
                component={RTLink}
                href="#"
                variant="body2"
                onClick={(e) => e.preventDefault()}
              >
                <RTBox component="i" color="white" className="fab fa-instagram" />
              </RTTypography>
              <RTTypography
                component={RTLink}
                href="#"
                variant="body2"
                onClick={(e) => e.preventDefault()}
              >
                <RTBox component="i" color="white" className="fab fa-twitter" />
              </RTTypography>
              <RTTypography
                component={RTLink}
                href="#"
                variant="body2"
                onClick={(e) => e.preventDefault()}
              >
                <RTBox component="i" color="white" className="fab fa-google-plus" />
              </RTTypography>
            </RTStack>
          </RTGrid>
        </RTContainer>
      </RTBox>
    </RTBox>
  );
}

export default HeaderThree;`;

export default headerThreeCode;
