import { RTContainer, RTGrid } from "rt-serverless-ui";

import { RTBox, RTAlert, RTTypography, RTDivider } from "rt-serverless-ui";

function AlertsWithLinks() {
  return (
    <RTBox component="section" py={6}>
      <RTContainer>
        <RTGrid container item xs={12} lg={10} mx="auto">
          <RTGrid item xs={12}>
            <RTAlert color="error">
              A simple error alert with an&nbsp;
              <RTTypography component="a" href="#" variant="body2" fontWeight="bold" color="white">
                example link
              </RTTypography>
              . Give it a click if you like.
            </RTAlert>
          </RTGrid>
          <RTGrid item xs={12}>
            <RTAlert color="success">
              <RTBox>
                <RTTypography variant="h4" color="white" mb={1}>
                  Good job!
                </RTTypography>
                <RTTypography variant="body2" color="white">
                  That&apos;s the main thing people are controlled by! Thoughts- their perception of
                  themselves! They&apos;re slowed down by their perception of themselves. If
                  you&apos;re taught you can&apos;t do anything, you won&apos;t do anything. I was
                  taught I could do everything.
                </RTTypography>
                <RTDivider light />
                <RTTypography variant="body2" color="white">
                  What else could rust the heart more over time? Blackgold.
                </RTTypography>
              </RTBox>
            </RTAlert>
          </RTGrid>
          <RTGrid item xs={12}>
            <RTAlert color="warning" dismissible>
              <strong>Holy molly!</strong>&nbsp; You should check in on some of those fields below.
            </RTAlert>
          </RTGrid>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default AlertsWithLinks;
