// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/View";

// Buttons page components
import ButtonsGradient from "layouts/sections/elements/buttons/components/ButtonsGradient";
import ButtonsContained from "layouts/sections/elements/buttons/components/ButtonsContained";
import ButtonsOutlined from "layouts/sections/elements/buttons/components/ButtonsOutlined";
import ButtonsSizes from "layouts/sections/elements/buttons/components/ButtonsSizes";
import ButtonsIconLeft from "layouts/sections/elements/buttons/components/ButtonsIconLeft";
import ButtonsIconRight from "layouts/sections/elements/buttons/components/ButtonsIconRight";

// Buttons page components code
import buttonsGradientCode from "layouts/sections/elements/buttons/components/ButtonsGradient/code";
import buttonsContainedCode from "layouts/sections/elements/buttons/components/ButtonsContained/code";
import buttonsOutlinedCode from "layouts/sections/elements/buttons/components/ButtonsOutlined/code";
import buttonsSizesCode from "layouts/sections/elements/buttons/components/ButtonsSizes/code";
import buttonsIconLeftCode from "layouts/sections/elements/buttons/components/ButtonsIconLeft/code";
import buttonsIconRightCode from "layouts/sections/elements/buttons/components/ButtonsIconRight/code";
import { t } from "i18next";
function Buttons() {
  return (
    <BaseLayout
      title={t("Buttons")}
      breadcrumb={[
        { label: "Page_Sections", route: "/sections/elements/buttons" },
        { label: "Buttons" },
      ]}
    >
      <View title="Buttons" code={buttonsContainedCode}>
        <ButtonsContained />
      </View>
    </BaseLayout>
  );
}

export default Buttons;
