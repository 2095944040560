import { useState } from "react";

import { RTContainer, RTGrid, RTModal, RTDivider, RTSlide, RTIcon } from "rt-serverless-ui";

import { RTBox, RTButton, RTTypography } from "rt-serverless-ui";

function SimpleRTModal() {
  const [show, setShow] = useState(false);
  const toggleRTModal = () => setShow(!show);

  return (
    <RTBox component="section" py={6}>
      <RTContainer>
        <RTGrid container item xs={12} lg={10} justifyContent="center" mx="auto">
          <RTButton variant="gradient" color="info" onClick={toggleRTModal}>
            Launch Demo RTModal
          </RTButton>
        </RTGrid>
        <RTModal open={show} onClose={toggleRTModal} sx={{ display: "grid", placeItems: "center" }}>
          <RTSlide direction="down" in={show} timeout={500}>
            <RTBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <RTBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <RTTypography variant="h5">Your modal title</RTTypography>
                <RTIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleRTModal}>
                  close
                </RTIcon>
              </RTBox>
              <RTDivider sx={{ my: 0 }} />
              <RTBox p={2}>
                <RTTypography variant="body2" color="secondary" fontWeight="regular">
                  Society has put up so many boundaries, so many limitations on what&apos;s right
                  and wrong that it&apos;s almost impossible to get a pure thought out.
                  <br />
                  <br />
                  It&apos;s like a little kid, a little boy, looking at colors, and no one told him
                  what colors are good, before somebody tells you you shouldn&apos;t like pink
                  because that&apos;s for girls, or you&apos;d instantly become a gay two-year-old.
                </RTTypography>
              </RTBox>
              <RTDivider sx={{ my: 0 }} />
              <RTBox display="flex" justifyContent="space-between" p={1.5}>
                <RTButton variant="gradient" color="dark" onClick={toggleRTModal}>
                  close
                </RTButton>
                <RTButton variant="gradient" color="info">
                  save changes
                </RTButton>
              </RTBox>
            </RTBox>
          </RTSlide>
        </RTModal>
      </RTContainer>
    </RTBox>
  );
}

export default SimpleRTModal;
