// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/View";

// ALerts page components
import SimpleAlerts from "layouts/sections/attention-catchers/alerts/components/SimpleAlerts";
import AlertsWithLinks from "layouts/sections/attention-catchers/alerts/components/AlertsWithLinks";
import AlertWithContent from "layouts/sections/attention-catchers/alerts/components/AlertWithContent";
import DismissingAlert from "layouts/sections/attention-catchers/alerts/components/DismissingAlert";

// ALerts page components code
import simpleAlertsCode from "layouts/sections/attention-catchers/alerts/components/SimpleAlerts/code";
import alertsWithLinksCode from "layouts/sections/attention-catchers/alerts/components/AlertsWithLinks/code";
import alertWithContentCode from "layouts/sections/attention-catchers/alerts/components/AlertWithContent/code";
import dismissingAlertCode from "layouts/sections/attention-catchers/alerts/components/DismissingAlert/code";
import { t } from "i18next";
function Alerts() {
  return (
    <BaseLayout
      title={t("Alerts")}
      breadcrumb={[
        { label: "Page_Sections", route: "/sections/attention-catchers/alerts" },
        { label: "Alerts" },
      ]}
    >
      <View title="Alerts" code={alertsWithLinksCode}>
        <AlertsWithLinks />
      </View>
    </BaseLayout>
  );
}

export default Alerts;
