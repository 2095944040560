const dropdownAndDropupCode = `import { useState } from "react";

import { RTContainer, RTGrid, RTIcon, RTMenu, RTMenuItem } from "rt-serverless-ui";

import { RTBox, RTButton } from "rt-serverless-ui";
function DropdownAndDropup() {
  const [dropdown, setDropdown] = useState(null);
  const [dropup, setDropup] = useState(null);

  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);

  const openDropup = ({ currentTarget }) => setDropup(currentTarget);
  const closeDropup = () => setDropup(null);

  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownRTIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  const dropupRTIconStyles = {
    transform: dropup ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  return (
    <RTBox component="section" py={12}>
      <RTContainer>
        <RTGrid container spacing={3}>
          <RTGrid item xs={12} lg={6} textAlign="center">
            <RTButton variant="gradient" color="info" onClick={openDropdown}>
              Dropdown <RTIcon sx={dropdownRTIconStyles}>expand_more</RTIcon>
            </RTButton>
            <RTMenu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
              <RTMenuItem onClick={closeDropdown}>Action</RTMenuItem>
              <RTMenuItem onClick={closeDropdown}>Another action</RTMenuItem>
              <RTMenuItem onClick={closeDropdown}>Something else here</RTMenuItem>
            </RTMenu>
          </RTGrid>
          <RTGrid item xs={12} lg={6} textAlign="center">
            <RTButton variant="gradient" color="info" onClick={openDropup}>
              Dropup <RTIcon sx={dropupRTIconStyles}>expand_more</RTIcon>
            </RTButton>
            <RTMenu
              anchorEl={dropup}
              open={Boolean(dropup)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={closeDropup}
            >
              <RTMenuItem onClick={closeDropup}>Action</RTMenuItem>
              <RTMenuItem onClick={closeDropup}>Another action</RTMenuItem>
              <RTMenuItem onClick={closeDropup}>Something else here</RTMenuItem>
            </RTMenu>
          </RTGrid>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default DropdownAndDropup;`;

export default dropdownAndDropupCode;
