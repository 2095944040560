const featuresTwoCode = `
import { RTContainer, RTGrid } from "rt-serverless-ui";
import { RTBox } from "rt-serverless-ui";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function FeaturesTwo() {
  return (
    <RTBox component="section" py={12}>
      <RTContainer>
        <RTGrid container spacing={3} alignItems="center">
          <RTGrid item xs={12} lg={6}>
            <RTGrid container justifyContent="flex-start">
              <RTGrid item xs={12} md={6}>
                <RTBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Fully integrated"
                    description="We get insulted by others, lose trust for those We get back freezes"
                  />
                </RTBox>
              </RTGrid>
              <RTGrid item xs={12} md={6}>
                <RTBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Payments functionality"
                    description="We get insulted by others, lose trust for those We get back freezes"
                  />
                </RTBox>
              </RTGrid>
              <RTGrid item xs={12} md={6}>
                <RTBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Prebuilt components"
                    description="We get insulted by others, lose trust for those We get back freezes"
                  />
                </RTBox>
              </RTGrid>
              <RTGrid item xs={12} md={6}>
                <RTBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Improved platform"
                    description="We get insulted by others, lose trust for those We get back freezes"
                  />
                </RTBox>
              </RTGrid>
            </RTGrid>
          </RTGrid>
          <RTGrid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </RTGrid>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default FeaturesTwo;`;

export default featuresTwoCode;
