import { useState } from "react";

import { RTContainer, RTGrid, RTSnackbar } from "rt-serverless-ui";

import { RTBox, RTButton } from "rt-serverless-ui";
function ToastMessage() {
  const [show, setShow] = useState(false);
  const toggleRTSnackbar = () => setShow(!show);

  return (
    <RTBox component="section" py={6}>
      <RTContainer>
        <RTGrid container item xs={12} lg={10} py={2} justifyContent="center" mx="auto">
          <RTButton variant="gradient" color="dark" onClick={toggleRTSnackbar}>
            Show RTSnackbar
          </RTButton>
        </RTGrid>
        <RTSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          // color="dark"
          icon="notifications"
          title="Material Design"
          content="Hello, world! This is a notification message"
          dateTime="11 mins ago"
          open={show}
          close={toggleRTSnackbar}
          autoHideDuration={3000}
          onClose={toggleRTSnackbar}
        />
      </RTContainer>
    </RTBox>
  );
}

export default ToastMessage;
