/* eslint-disable no-template-curly-in-string */
const bgImage =
  "`${linearGradient(rgba(gradients.info.main, 0.5), rgba(gradients.info.state, 0.5))}, url(${bgImage})`";

const headerTwoCode = `import { useEffect, useRef } from "react";

// typed-js
import Typed from "typed.js";

import { RTContainer, RTLink, RTGrid } from "rt-serverless-ui";
import { RTBox, RTButton, RTTypography } from "rt-serverless-ui";

// Images
import bgImage from "assets/images/bg-about-us.jpg";

function HeaderTwo() {
  const typedJSRef = useRef(null);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["web design", "web development", "mobile development"],
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <RTBox component="header" position="relative" height="100%">
      <RTBox component="nav" position="absolute" top="0.5rem" width="100%">
        <RTContainer>
          <RTGrid container flexDirection="row" alignItems="center">
            <RTTypography
              component={RTLink}
              href="#"
              variant="button"
              color="white"
              fontWeight="regular"
              py={0.8125}
              mr={2}
            >
              Material Design
            </RTTypography>
            <RTButton
              variant="outlined"
              color="white"
              sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
            >
              <RTBox component="i" color="white" className="fas fa-bars" />
            </RTButton>
            <RTBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  Home
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  About Us
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  Contact Us
                </RTTypography>
              </RTBox>
            </RTBox>
            <RTBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <RTBox component="i" color="white" className="fab fa-twitter" />
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <RTBox component="i" color="white" className="fab fa-facebook" />
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <RTBox component="i" color="white" className="fab fa-instagram" />
                </RTTypography>
              </RTBox>
            </RTBox>
          </RTGrid>
        </RTContainer>
      </RTBox>
      <RTBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>${bgImage},
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <RTContainer>
          <RTGrid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <RTTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Our company mission is to lead the <span ref={typedJSRef} />
            </RTTypography>
            <RTTypography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
              The time is now for it to be okay to be great. People in this world shun people for
              being great. For being a bright color.
            </RTTypography>
            <RTButton color="white">contact us</RTButton>
          </RTGrid>
        </RTContainer>
      </RTBox>
    </RTBox>
  );
}

export default HeaderTwo;`;

export default headerTwoCode;
