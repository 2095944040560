const featuresThreeCode = `
import { RTContainer, RTGrid } from "rt-serverless-ui";
import { RTBox, RTTypography } from "rt-serverless-ui";

import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function FeaturesThree() {
  return (
    <RTBox component="section" py={12}>
      <RTContainer>
        <RTGrid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={6}
        >
          <RTTypography variant="h2" mb={1}>
            Turn your idea into a startup
          </RTTypography>
          <RTTypography variant="body1" color="text">
            We&apos;re constantly trying to express ourselves and actualize our dreams. If you have
            the opportunity to play{" "}
          </RTTypography>
        </RTGrid>
        <RTGrid container spacing={3}>
          <RTGrid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="primary"
              icon="person"
              title="Check our team"
              description="People in this world shun people for being great. For being a bright color. For standing out."
              direction="center"
            />
          </RTGrid>
          <RTGrid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              icon="support_agent"
              title="Support 24/7"
              description="We get insulted by others, lose trust for those others. We get back here to follow my dreams"
              direction="center"
            />
          </RTGrid>
          <RTGrid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="warning"
              icon="update"
              title="Unlimited revisions"
              description="The time is now to be okay to be the greatest you. Would you believe in what you believe in?"
              direction="center"
            />
          </RTGrid>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default FeaturesThree;`;

export default featuresThreeCode;
