import { RTContainer, RTGrid, RTAutocomplete } from "rt-serverless-ui";

import { RTBox, RTInput } from "rt-serverless-ui";
function SelectPicker() {
  return (
    <RTBox component="section" py={12}>
      <RTContainer>
        <RTGrid container justifyContent="center">
          <RTAutocomplete
            defaultValue="Washington"
            options={["Brazil", "Bucharest", "London", "Washington"]}
            sx={{ width: 300 }}
            renderInput={(params) => <RTInput {...params} variant="standard" />}
          />
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default SelectPicker;
