// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  RTAlert,
  RTBox,
  RTTypography,
  RTDivider,
  RTLink,
  RTTable,
  RTButton,
} from "rt-serverless-ui";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useLocation, NavLink } from "react-router-dom";
// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import BaseLayout from "layouts/sections/components/BaseLayout";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

function AppBarAPI() {
  const columns = [
    { field: "id", headerName: "Name", width: 170, sortable: false },
    {
      field: "Type",
      headerName: "Type",
      width: 130,
      sortable: false,
    },
    {
      field: "Default",
      headerName: "Default",
      width: 130,
      sortable: false,
    },
    {
      field: "Description",
      headerName: "Description",
      disableColumnMenu: true,
      width: 700,
      sortable: false,
    },
  ];

  const rows = [
    {
      id: "children",
      Type: "node",
      Default: "-",
      Description: "The content of the component.",
    },
    {
      id: "classes",
      Type: `
        'default'
        'inherit'
        'primary'
        'secondary'
        'transparent'
        'error'
        'info'
        'success'
        'warning'
        string`,
      Default: "primary",
      Description: "The color of the component. It supports both default and custom theme colors.",
    },
    {
      id: "enableColorOnDark",
      Type: "bool",
      Default: "false",
      Description: "If true, the color prop is applied in dark mode.",
    },
    {
      id: "position",
      Type: `
       'absolute'
       'fixed'
       'relative'
       'static'
       'sticky'`,
      Default: "fixed",
      Description:
        "The positioning type. The behavior of the different options is described in the MDN web docs.",
      //   Description: (
      //     <b>
      //       The positioning type. The behavior of the different options is described
      //       <a
      //         href="https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Positioning"
      //         target="_blank"
      //       >
      //         in the MDN web docs
      //       </a>
      //     </b>
      //   ),
    },
  ];
  return (
    <>
      {/* <BaseLayout
      // title={t("pageheader")}
      // breadcrumb={[
      //   { label: "Page_Sections", route: "/sections/page-sections/page-headers" },
      //   { label: "pageheader" },
      // ]}
      > */}
      <MKBox
        component="section"
        pt={2}
        pb={12}
        sx={{
          height: 300,
          width: "100%",
          //   [`.${gridClasses.cell}.cold`]: {
          //     backgroundColor: "#b9d5ff91",
          //     color: "#1a3e72",
          //   },
          [`.${gridClasses.cell}.hot`]: {
            // backgroundColor: "#ff943975",
            color: "#1890ff",
          },
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h4" mt={6} mb={3}>
                    RTAppBar API
                  </MKTypography>
                  <RTTypography variant="h5" mb={3} ml={3}>
                    Demos
                  </RTTypography>
                  {/* <RTAlert color="success" sx={{ backGroundColor: "#6ae79c" }}> */}
                  <RTBox>
                    <NavLink to={"/sections/page-sections/pricing"}>
                      <RTButton
                        ml={5}
                        color={"blue"}
                        sx={{ fontSize: 20, color: "#1890ff", textTransform: "none" }}
                      >
                        RTAppBar Demos
                      </RTButton>
                    </NavLink>
                    {/* <RTLink
                        key={"input"}
                        href={"/sections/input-areas/inputs"}
                        target="_blank"
                        rel="noreferrer"
                        // color={"blue"}
                        //   sx={{ textDecoration: "none" }}
                      >
                        {"> "}RTInput
                      </RTLink> */}
                  </RTBox>
                  {/* </RTAlert> */}
                  <MKTypography variant="h5" mt={3} mb={2} ml={3}>
                    Props Information
                  </MKTypography>
                  <RTBox ml={3}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pagination={false}
                      disableColumnMenu={true}
                      disableColumnFilter={true}
                      disableSelectionOnClick={true}
                      disableRowEdit={true}
                      disableColumnResize={true}
                      // density="compact"
                      hideToolbar={true}
                      hideFooter={true}
                      headerBackgroundColor="#fff"
                      headerTitleColor="#525f7f"
                      // sx={{ border: 1, borderColor: "divider" }}
                      getCellClassName={(params) => {
                        if (params.field === "Type" || params.field === "Default") {
                          return "hot";
                        }
                        // return "hot";
                      }}
                    />
                  </RTBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      {/* </BaseLayout> */}
    </>
  );
}

export default AppBarAPI;
