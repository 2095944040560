export default {
  lange: "中",
  welcome: "Welcome to serverless web Pro",
  create_account: "create account",
  counttitle1: "Coded Elements",
  countdes1: "From buttons, to inputs, navbars, alerts or cards, you are covered",
  counttitle2: "Design Blocks",
  countdes2: "Mix the sections, change the colors and unleash your creativity",
  counttitle3: "Pages",
  countdes3: "Save 3-4 weeks of work when you use our pre-made pages for your website",
  pages: "pages",
  login: {
    confirm: "Login",
    reset: "Reset",
  },
  home: {
    welcome: "Welcome",
  },
  tabs: {
    more: "More",
    closeCurrent: "Current",
    closeOther: "Other",
    closeAll: "All",
  },
  header: {
    componentSize: "Component Size",
    language: "Language",
    theme: "theme",
    themeSetting: "Theme setting",
    darkMode: "Dark Mode",
    lightMode: "Light Mode",
    fullScreen: "Full Screen",
    exitFullScreen: "Exit Full Screen",
    personalData: "Personal Data",
    changePassword: "Change Password",
    logout: "Logout",
  },
};
