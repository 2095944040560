const featuresThreeCode = `//
import Icon from "@mui/material/Icon";
import { RTContainer, RTGrid } from "rt-serverless-ui";
import { RTBox, RTButton, RTTypography } from "rt-serverless-ui";

function PricingThree() {
  return (
    <RTBox component="section" py={{ xs: 12, lg: 18 }}>
      <RTContainer>
        <RTGrid
          container
          item
          xs={12}
          md={6}
          justifyContent="center"
          sx={{ mx: "auto", mb: 8, textAlign: "center" }}
        >
          <RTTypography variant="h2" mb={1}>
            Best no-tricks pricing
          </RTTypography>
          <RTTypography variant="body1" color="text">
            If you&apos;re not satisfied, contact us within the first 30 days and we&apos;ll send
            you a full refund.
          </RTTypography>
        </RTGrid>
        <RTGrid container item xs={12}>
          <Card sx={{ width: "100%" }}>
            <RTGrid container alignItems="center">
              <RTGrid item xs={12} lg={8}>
                <RTBox py={3} px={4}>
                  <RTTypography variant="h3" mb={1}>
                    Lifetime Membership
                  </RTTypography>
                  <RTTypography variant="body2" color="text" fontWeight="regular">
                    You have Free Unlimited Updates and Premium Support on each package. You also
                    have 30 days to request a refund.
                  </RTTypography>
                  <RTGrid container item xs={12} lg={3} sx={{ mt: 6, mb: 1 }}>
                    <RTTypography variant="h6">What&apos;s included</RTTypography>
                  </RTGrid>
                  <RTGrid container spacing={3}>
                    <RTGrid item xs={12} md={6}>
                      <RTBox display="flex" py={1} pr={1} lineHeight={0}>
                        <RTTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </RTTypography>
                        <RTTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                          Private code access
                        </RTTypography>
                      </RTBox>
                      <RTBox display="flex" py={1} pr={1} lineHeight={0}>
                        <RTTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </RTTypography>
                        <RTTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                          Free entry to all repositories
                        </RTTypography>
                      </RTBox>
                    </RTGrid>
                    <RTGrid item xs={12} md={6}>
                      <RTBox display="flex" py={1} pr={1} lineHeight={0}>
                        <RTTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </RTTypography>
                        <RTTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                          Pro member accounts
                        </RTTypography>
                      </RTBox>
                      <RTBox display="flex" py={1} pr={1} lineHeight={0}>
                        <RTTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </RTTypography>
                        <RTTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                          Support team full assist
                        </RTTypography>
                      </RTBox>
                    </RTGrid>
                  </RTGrid>
                </RTBox>
              </RTGrid>
              <RTGrid item xs={12} lg={4}>
                <RTBox p={3} textAlign="center">
                  <RTTypography variant="h6" mt={{ xs: 0, sm: 3 }}>
                    Pay once, own it forever
                  </RTTypography>
                  <RTTypography variant="h1">
                    <RTBox component="small">$</RTBox>399
                  </RTTypography>
                  <RTButton variant="gradient" color="error" size="large" sx={{ my: 2 }}>
                    Get Access
                  </RTButton>
                  <RTTypography display="block" variant="button" color="text" fontWeight="regular">
                    Get a free sample (20MB)
                  </RTTypography>
                </RTBox>
              </RTGrid>
            </RTGrid>
          </Card>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default PricingThree;`;

export default featuresThreeCode;
