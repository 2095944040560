import { RTTypography } from "rt-serverless-ui";

import DetailedFooter from "examples/Footers/DetailedFooter";

function FooterOne() {
  const date = new Date().getFullYear();

  const footerContent = {
    brand: {
      name: "MATERIAL UI DESIGN",
      description: "The next generation of design systems.",
    },
    socials: [
      {
        icon: <i className="fab fa-facebook" />,
        link: "https://www.facebook.com//",
      },
      {
        icon: <i className="fab fa-twitter" />,
        link: "https://twitter.com/",
      },
      {
        icon: <i className="fab fa-instagram" />,
        link: "https://www.instagram.com/official/",
      },
      {
        icon: <i className="fab fa-pinterest" />,
        link: "https://ro.pinterest.com/the/",
      },
      {
        icon: <i className="fab fa-github" />,
        link: "https://github.com/official",
      },
    ],
    menus: [
      {
        name: "company",
        items: [
          { name: "about us", href: "" },
          { name: "career", href: "" },
          { name: "press", href: "" },
          { name: "blog", href: "" },
        ],
      },
      {
        name: "Pages",
        items: [
          { name: "login", href: "" },
          { name: "register", href: "" },
          { name: "add list", href: "" },
          { name: "contact", href: "" },
        ],
      },
      {
        name: "legal",
        items: [
          { name: "terms", href: "" },
          { name: "about us", href: "" },
          { name: "team", href: "https://services.creative-tim.com" },
          { name: "privacy", href: "" },
        ],
      },
      {
        name: "resources",
        items: [
          { name: "blog", href: "" },
          { name: "services", href: "" },
          { name: "product", href: "" },
          { name: "pricing", href: "" },
        ],
      },
    ],
    copyright: (
      <RTTypography variant="button" color="secondary">
        Copyright &copy; {date} Material Design by{" "}
        <RTTypography
          component="a"
          href=""
          target="_blank"
          rel="noreferrer"
          variant="button"
          color="secondary"
        ></RTTypography>
        .
      </RTTypography>
    ),
  };

  return <DetailedFooter content={footerContent} />;
}

export default FooterOne;
