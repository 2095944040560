import { useState } from "react";

import { RTContainer, RTGrid, RTIcon } from "rt-serverless-ui";

import { Snackbar } from "@mui/material";

import { RTBox, RTButton } from "rt-serverless-ui";

function ToastBasic() {
  const [show, setShow] = useState(false);
  const toggleRTSnackbar = () => setShow(!show);
  const toastStyles = ({
    palette: { info },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: info.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <RTBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      Hello, world! This is a notification message.
    </RTBox>
  );
  return (
    <RTBox component="section" py={6}>
      <RTContainer>
        <RTGrid container item xs={12} lg={10} justifyContent="center" mx="auto">
          <RTButton variant="gradient" color="info" onClick={toggleRTSnackbar}>
            Show RTSnackbar
          </RTButton>
        </RTGrid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={show}
          autoHideDuration={3000}
          onClose={toggleRTSnackbar}
          message={toastTemplate}
          action={
            <RTIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleRTSnackbar}>
              close
            </RTIcon>
          }
          sx={toastStyles}
        />
      </RTContainer>
    </RTBox>
  );
}

export default ToastBasic;
