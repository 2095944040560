import * as React from "react";
import { RTTableExport } from "rt-serverless-ui";
import { useMovieData } from "./useMovieData";

export default function TableFour() {
  const data = useMovieData();
  const VISIBLE_FIELDS = ["title", "company", "director", "year", "cinematicUniverse"];
  const columns = React.useMemo(
    () => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [data.columns]
  );
  return <RTTableExport rows={data.rows} columns={columns} disableColumnMenu={true} />;
}
