const formSimpleCode = `
import { useState } from "react";

import { RTContainer, RTGrid, RTSwitch } from "rt-serverless-ui";

import { RTBox, RTInput, RTButton, RTTypography } from "rt-serverless-ui";
function FormSimple() {
  const [checked, setChecked] = useState(true);

  const handleChecked = () => setChecked(!checked);

  return (
    <RTBox component="section" py={12}>
      <RTContainer>
        <RTGrid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <RTTypography variant="h3" mb={1}>
            Contact Us
          </RTTypography>
        </RTGrid>
        <RTGrid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <RTBox width="100%" component="form" method="post" autoComplete="off">
            <RTBox p={3}>
              <RTGrid container spacing={3}>
                <RTGrid item xs={12} md={6}>
                  <RTInput variant="standard" label="First Name" fullWidth />
                </RTGrid>
                <RTGrid item xs={12} md={6}>
                  <RTInput variant="standard" label="Last Name" fullWidth />
                </RTGrid>
                <RTGrid item xs={12}>
                  <RTInput variant="standard" type="email" label="Email Address" fullWidth />
                </RTGrid>
                <RTGrid item xs={12}>
                  <RTInput variant="standard" label="Your Message" multiline fullWidth rows={6} />
                </RTGrid>
                <RTGrid item xs={12} alignItems="center" ml={-1}>
                  <RTSwitch checked={checked} onChange={handleChecked} />
                  <RTTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    ml={-1}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    onClick={handleChecked}
                  >
                    &nbsp;&nbsp;I agree the&nbsp;
                  </RTTypography>
                  <RTTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    Terms and Conditions
                  </RTTypography>
                </RTGrid>
              </RTGrid>
              <RTGrid container item justifyContent="center" xs={12} my={2}>
                <RTButton type="submit" variant="gradient" color="dark" fullWidth>
                  Send Message
                </RTButton>
              </RTGrid>
            </RTBox>
          </RTBox>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default FormSimple;`;

export default formSimpleCode;
