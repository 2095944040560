const tableTwoCode = `import { RTTable, RTCard } from "rt-serverless-ui";
export default function TableTwo() {
  const columns = [
    { field: "id", headerName: "ID", width: 70, sortable: false },
    { field: "orderNumber", headerName: "注文番号", width: 130, sortable: false },
    { field: "orderDate", headerName: "注文日", type: "date", width: 130, sortable: false },
    {
      field: "model",
      headerName: "型式",
      width: 90,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "number",
      headerName: "数量",
      type: "number",
      with: "70",
      sortable: false,
    },
    {
      field: "wishDate",
      headerName: "希望納期",
      width: 160,
      type: "date",
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const rows = [
    {
      id: 1,
      orderNumber: "012401369",
      orderDate: new Date(2024, 3, 1),
      model: "Ts22",
      number: 20,
      wishDate: new Date(2024, 3, 1),
    },
    {
      id: 2,
      orderNumber: "012401368",
      orderDate: new Date(2024, 3, 2),
      model: "Ts22",
      number: 60,
      wishDate: new Date(2024, 3, 1),
    },
    {
      id: 3,
      orderNumber: "012401367",
      orderDate: new Date(2024, 3, 3),
      model: "Ts22",
      number: 20,
      wishDate: new Date(2024, 3, 1),
    },
    {
      id: 4,
      orderNumber: "012401366",
      orderDate: new Date(2024, 3, 4),
      model: "Ts23",
      number: 20,
      wishDate: new Date(2024, 3, 1),
    },
    {
      id: 5,
      orderNumber: "012401365",
      orderDate: new Date(2024, 3, 5),
      model: "Ts22",
      number: 20,
      wishDate: new Date(2024, 3, 1),
    },
    {
      id: 6,
      orderNumber: "012401364",
      orderDate: new Date(2024, 3, 6),
      model: "Ts22",
      number: 20,
      wishDate: new Date(2024, 3, 1),
    },
  ];
  return (
    <div>
      <RTCard sx={{ width: "fit-content" }}>
        <RTTable
          rows={rows}
          columns={columns}
          headerBackgroundColor="#007bff"
          headerTitleColor={"#fff"}
        />
      </RTCard>
    </div>
  );
};`;

export default tableTwoCode;
