const featuresOneCode = `import { useState } from "react";

import { RTContainer, RTAppBar, RTTabs, RTTab, RTGrid } from "rt-serverless-ui";
import { RTBox, RTTypography } from "rt-serverless-ui";

import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
function PricingOne() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <RTBox component="section" pb={3}>
      <RTBox variant="gradient" bgColor="dark">
        <RTContainer sx={{ pb: { xs: 12, lg: 22 }, pt: 12 }}>
          <RTGrid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            md={8}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <RTTypography variant="h3" color="white" mb={2}>
              See our pricing
            </RTTypography>
            <RTTypography variant="body2" color="white">
              You have Free Unlimited Updates and Premium Support on each package.
            </RTTypography>
          </RTGrid>
        </RTContainer>
      </RTBox>
      <RTBox mt={-16}>
        <RTContainer>
          <RTGrid container sx={{ mb: 6 }}>
            <RTGrid item xs={7} md={6} lg={4} sx={{ mx: "auto", textAlign: "center" }}>
              <RTAppBar position="static">
                <RTTabs value={activeTab} onChange={handleTabType}>
                  <RTTab
                    id="monthly"
                    label={
                      <RTBox py={0.5} px={2} color="inherit">
                        Monthly
                      </RTBox>
                    }
                  />
                  <RTTab
                    id="annual"
                    label={
                      <RTBox py={0.5} px={2} color="inherit">
                        Annual
                      </RTBox>
                    }
                  />
                </RTTabs>
              </RTAppBar>
            </RTGrid>
          </RTGrid>
          <RTBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
            <RTGrid container spacing={3} justifyContent="center">
              <RTGrid item xs={12} lg={4}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "starter" }}
                  price={{ currency: "$", value: tabType === "annual" ? 119 : 59, type: "mo" }}
                  specifications={[
                    { label: "2 team members", includes: true },
                    { label: "20GB Cloud storage", includes: true },
                    { label: "Integration help", includes: false },
                    { label: "Sketch Files", includes: false },
                    { label: "API Access", includes: false },
                    { label: "Complete documentation", includes: false },
                  ]}
                  action={{
                    type: "internal",
                    route: "/",
                    color: "dark",
                    label: "join",
                  }}
                />
              </RTGrid>
              <RTGrid item xs={12} lg={4}>
                <DefaultPricingCard
                  color="dark"
                  badge={{ color: "info", label: "premium" }}
                  price={{ currency: "$", value: tabType === "annual" ? 159 : 89, type: "mo" }}
                  specifications={[
                    { label: "10 team members", includes: true },
                    { label: "40GB Cloud storage", includes: true },
                    { label: "Integration help", includes: true },
                    { label: "Sketch Files", includes: true },
                    { label: "API Access", includes: false },
                    { label: "Complete documentation", includes: false },
                  ]}
                  action={{
                    type: "internal",
                    route: "/",
                    color: "info",
                    label: "try premium",
                  }}
                />
              </RTGrid>
              <RTGrid item xs={12} lg={4}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "enterprise" }}
                  price={{ currency: "$", value: tabType === "annual" ? 99 : 399, type: "mo" }}
                  specifications={[
                    { label: "Unlimited team members", includes: true },
                    { label: "100GB Cloud storage", includes: true },
                    { label: "Integration help", includes: true },
                    { label: "Sketch Files", includes: true },
                    { label: "API Access", includes: true },
                    { label: "Complete documentation", includes: true },
                  ]}
                  action={{
                    type: "internal",
                    route: "/",
                    color: "dark",
                    label: "join",
                  }}
                />
              </RTGrid>
            </RTGrid>
          </RTBox>
        </RTContainer>
      </RTBox>
    </RTBox>
  );
}

export default PricingOne;`;

export default featuresOneCode;
