// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/View";

// Tables page components
import TableOne from "layouts/sections/elements/tables/components/TableOne";

// Tables page components code
import tableOneCode from "layouts/sections/elements/tables/components/TableOne/code";
import tableTwoCode from "layouts/sections/elements/tables/components/TableTwo/code";
import { t } from "i18next";
import TableTwo from "./components/TableTwo";
import TableThree from "./components/TableThree/index";
import tableThreeCode from "./components/TableThree/code";
import TableFour from "./components/TableFour";
import TableFive from "./components/TableFive";
import tableFourCode from "./components/TableFour/code";
import tableFiveCode from "./components/TableFive/code";
function Tables() {
  return (
    <BaseLayout
      title={t("Tables")}
      breadcrumb={[
        { label: "Page Sections", route: "/sections/elements/tables" },
        { label: "Tables" },
      ]}
    >
      <View title="Basic Table" code={tableTwoCode}>
        <TableTwo />
      </View>
      <View title="Pagination Table" code={tableThreeCode}>
        <TableThree />
      </View>
      <View title="Filter-ColumnsSelector Table" code={tableOneCode}>
        <TableOne />
      </View>
      <View title="MultiFilters-ExportCSV Table" code={tableFourCode}>
        <TableFour />
      </View>
      <View title="Editable Table" code={tableFiveCode}>
        <TableFive />
      </View>
    </BaseLayout>
  );
}

export default Tables;
