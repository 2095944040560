import { RTContainer, RTStack, RTLink, RTGrid } from "rt-serverless-ui";
import { RTBox, RTButton, RTTypography } from "rt-serverless-ui";
// Images
import bgImage from "assets/images/bg-coworking.jpeg";

function HeaderOne() {
  return (
    <RTBox component="header" position="relative" height="100%">
      <RTBox component="nav" position="absolute" top="0.5rem" width="100%">
        <RTContainer>
          <RTGrid container flexDirection="row" alignItems="center">
            <RTTypography
              component={RTLink}
              href="#"
              variant="button"
              color="white"
              fontWeight="regular"
              py={0.8125}
              mr={2}
            >
              Material Design
            </RTTypography>
            <RTButton
              variant="outlined"
              color="white"
              sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
            >
              <RTBox component="i" color="white" className="fas fa-bars" />
            </RTButton>
            <RTBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  Home
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  About Us
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  Contact Us
                </RTTypography>
              </RTBox>
            </RTBox>
            <RTBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <RTBox component="i" color="white" className="fab fa-twitter" />
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <RTBox component="i" color="white" className="fab fa-facebook" />
                </RTTypography>
              </RTBox>
              <RTBox component="li">
                <RTTypography
                  component={RTLink}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <RTBox component="i" color="white" className="fab fa-instagram" />
                </RTTypography>
              </RTBox>
            </RTBox>
          </RTGrid>
        </RTContainer>
      </RTBox>
      <RTBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <RTContainer>
          <RTGrid
            container
            item
            xs={12}
            md={7}
            lg={6}
            flexDirection="column"
            justifyContent="center"
          >
            <RTTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Material Kit
            </RTTypography>
            <RTTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
              The time is now for it be okay to be great. People in this world shun people for being
              nice.
            </RTTypography>
            <RTStack direction="row" spacing={1} mt={3}>
              <RTButton color="white">Get Started</RTButton>
              <RTButton variant="text" color="white">
                Read more
              </RTButton>
            </RTStack>
          </RTGrid>
        </RTContainer>
      </RTBox>
    </RTBox>
  );
}

export default HeaderOne;
