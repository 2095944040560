import * as React from "react";
import { RTTableEdit } from "rt-serverless-ui";

const initialRows = [
  {
    id: "e4e37935-b0b0-5f3f-b3d6-ed48512c4d02",
    name: "Lawrence Townsend",
    age: 25,
    joinDate: new Date("2023-08-23T23:44:35.798Z"),
    role: "Market",
  },
  {
    id: "3ee62dcb-7480-5312-baae-49c9599ef567",
    name: "Cora Barber",
    age: 36,
    joinDate: new Date("2023-08-30T14:53:14.022Z"),
    role: "Finance",
  },
  {
    id: "b174f8cc-b38d-5f62-a30a-2c085aaa2d2d",
    name: "Katie Hines",
    age: 19,
    joinDate: new Date("2024-06-08T20:30:37.005Z"),
    role: "Development",
  },
];

export default function TableFive() {
  const oldcums = [
    { field: "name", headerName: "Name", width: 180, editable: true },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 80,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "joinDate",
      headerName: "Join date",
      type: "date",
      width: 180,
      editable: true,
    },
    {
      field: "role",
      headerName: "Department",
      width: 220,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Market", "Finance", "Development"],
    },
  ];
  return <RTTableEdit rows={initialRows} columns={oldcums} />;
}
