import { RTContainer, RTGrid, RTStack, RTIcon } from "rt-serverless-ui";

import { RTBox, RTButton } from "rt-serverless-ui";
function ButtonsContained() {
  return (
    <RTBox component="section" py={12}>
      <RTContainer>
        <RTGrid container justifyContent="center" py={2}>
          <RTStack direction="row" alignItems="flex-end" spacing={1}>
            <RTButton color="primary">primary</RTButton>
            <RTButton color="secondary">secondary</RTButton>
            <RTButton color="info">info</RTButton>
            <RTButton color="success">success</RTButton>
            <RTButton color="warning">warning</RTButton>
            <RTButton color="error">error</RTButton>
            <RTButton color="light">light</RTButton>
            <RTButton color="dark">dark</RTButton>
            <RTButton color="white">White</RTButton>
          </RTStack>
        </RTGrid>
        <RTGrid container justifyContent="center" py={2}>
          <RTStack direction="row" alignItems="flex-end" spacing={1}>
            <RTButton variant="outlined" color="primary">
              primary
            </RTButton>
            <RTButton variant="outlined" color="secondary">
              secondary
            </RTButton>
            <RTButton variant="outlined" color="info">
              info
            </RTButton>
            <RTButton variant="outlined" color="success">
              success
            </RTButton>
            <RTButton variant="outlined" color="warning">
              warning
            </RTButton>
            <RTButton variant="outlined" color="error">
              error
            </RTButton>
            <RTButton variant="outlined" color="light">
              light
            </RTButton>
            <RTButton variant="outlined" color="dark">
              dark
            </RTButton>
            <RTButton variant="outlined" color="white">
              White
            </RTButton>
          </RTStack>
        </RTGrid>
        <RTGrid container justifyContent="center">
          <RTStack direction="row" alignItems="center" spacing={1}>
            <RTButton color="info" size="small">
              <RTIcon sx={{ mr: 1 }}>favorite</RTIcon>
              small
            </RTButton>
            <RTButton color="info">
              <RTIcon sx={{ mr: 1 }}>favorite</RTIcon>
              default
            </RTButton>
            <RTButton color="info" size="large">
              <RTIcon sx={{ mr: 1 }}>favorite</RTIcon>
              large
            </RTButton>
          </RTStack>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default ButtonsContained;
