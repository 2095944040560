// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import Breadcrumbs from "examples/Breadcrumbs";

// Routes
import routes from "routes";
import { t } from "i18next";
function BaseLayout({ breadcrumb, title, children }) {
  return (
    <MKBox display="flex" flexDirection="column" bgColor="white" minHeight="100vh">
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/authentication/sign-in/cover",
          label: "sign_in",
          color: "info",
        }}
        sticky
      />
      <Container sx={{ mt: 2 }}>
        <Grid container item xs={12} flexDirection="column" justifyContent="center" mx="auto">
          {/* <MKBox width={{ xs: "100%", md: "50%", lg: "29%" }} mb={3}>
            <Breadcrumbs routes={breadcrumb} />
          </MKBox> */}
          <MKTypography variant="h3" mb={1}>
            {t(title)}
          </MKTypography>
          {children}
        </Grid>
      </Container>
      {/* <MKBox mt="auto">
        <CenteredFooter />
      </MKBox> */}
    </MKBox>
  );
}

// Typechecking props for the BaseLayout
BaseLayout.propTypes = {
  breadcrumb: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
