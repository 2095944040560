import { RTContainer, RTGrid } from "rt-serverless-ui";

import { RTBox, RTInput } from "rt-serverless-ui";

function InputOutlined() {
  return (
    <RTBox component="section" py={12}>
      <RTContainer>
        <RTGrid container item xs={12} lg={4} py={2} mx="auto">
          <RTInput variant="standard" label="Regular" fullWidth />
        </RTGrid>
        <RTGrid container item xs={12} lg={4} py={2} mx="auto">
          <RTInput
            variant="standard"
            label="Full Name"
            placeholder="eg. Thomas Shelby"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </RTGrid>
        <RTGrid container item xs={12} lg={4} py={2} mx="auto">
          <RTInput label="Outline" fullWidth />
        </RTGrid>
        <RTGrid container item xs={12} lg={4} py={2} mx="auto">
          <RTInput label="Error" fullWidth error />
        </RTGrid>
        <RTGrid container item xs={12} lg={4} py={2} mx="auto">
          <RTInput label="Disabled" fullWidth disabled />
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default InputOutlined;
