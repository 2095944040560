// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/View";

// Stats page components
import StatsOne from "layouts/sections/page-sections/stats/components/StatsOne";
import StatsTwo from "layouts/sections/page-sections/stats/components/StatsTwo";
import StatsThree from "layouts/sections/page-sections/stats/components/StatsThree";

// Stats page components code
import statsOneCode from "layouts/sections/page-sections/stats/components/StatsOne/code";
import statsTwoCode from "layouts/sections/page-sections/stats/components/StatsTwo/code";
import statsThreeCode from "layouts/sections/page-sections/stats/components/StatsThree/code";
import { t } from "i18next";

function Stats() {
  return (
    <BaseLayout
      title={t("stats")}
      breadcrumb={[
        { label: "Page Sections", route: "/sections/page-sections/stats" },
        { label: "stats" },
      ]}
    >
      <View title="Stats 1" code={statsOneCode}>
        <StatsOne />
      </View>
      <View title="Stats 2" code={statsTwoCode}>
        <StatsTwo />
      </View>
    </BaseLayout>
  );
}

export default Stats;
