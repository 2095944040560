const statsOneCode = `
import { RTContainer, RTGrid } from "rt-serverless-ui";
import { RTBox } from "rt-serverless-ui";

import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function StatsOne() {
  return (
    <RTBox component="section" py={3}>
      <RTContainer>
        <RTGrid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <RTGrid item xs={12} md={4}>
            <DefaultCounterCard
              count={300}
              title="Projects"
              description="Of “high-performing” level are led by a certified project manager"
            />
          </RTGrid>
          <RTGrid item xs={12} md={4} display="flex">
            <DefaultCounterCard
              count={70}
              title="Hours"
              description="That meets quality standards required by our users"
            />
          </RTGrid>
          <RTGrid item xs={12} md={4}>
            <DefaultCounterCard
              count={30}
              title="Support"
              description="Actively engage team members that finishes on time"
            />
          </RTGrid>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default StatsOne;`;

export default statsOneCode;
