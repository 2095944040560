const featuresTwoCode = `import { useState } from "react";

import { RTContainer, RTAppBar, RTTabs, RTTab, RTGrid } from "rt-serverless-ui";
import { RTBox, RTTypography } from "rt-serverless-ui";

import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";

function PricingTwo() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  return (
    <RTBox component="section" py={{ xs: 0, md: 12 }}>
      <RTContainer>
        <RTGrid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <RTTypography variant="h3">Pick the best plan for you</RTTypography>
          <RTTypography variant="body2" color="text">
            You have Free Unlimited Updates and Premium Support on each package.
          </RTTypography>
        </RTGrid>
        <RTGrid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <RTAppBar position="static">
            <RTTabs value={activeTab} onChange={handleTabType}>
              <RTTab
                id="monthly"
                label={
                  <RTBox py={0.5} px={2} color="inherit">
                    Monthly
                  </RTBox>
                }
              />
              <RTTab
                id="quarterly"
                label={
                  <RTBox py={0.5} px={2} color="inherit">
                    Quarterly
                  </RTBox>
                }
              />
              <RTTab
                id="annual"
                label={
                  <RTBox py={0.5} px={2} color="inherit">
                    Annual
                  </RTBox>
                }
              />
              <RTTab
                id="lifetime-access"
                label={
                  <RTBox py={0.5} px={2} color="inherit">
                    Lifetime access
                  </RTBox>
                }
              />
            </RTTabs>
          </RTAppBar>
        </RTGrid>
        <RTGrid container spacing={3} mt={6}>
          <RTGrid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Starter"
              description="Free access for 2 members"
              price={{ value: "$199", type: "year" }}
              action={{ type: "internal", route: "/", label: "sign_in" }}
              specifications={["Complete documentation", "Working materials in Sketch"]}
            />
          </RTGrid>
          <RTGrid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Pro"
              description="Free access for 30 members"
              price={{ value: "$299", type: "year" }}
              action={{ type: "internal", route: "/", label: "sign_in" }}
              specifications={[
                "Complete documentation",
                "Working materials in Sketch",
                "2GB cloud storage",
              ]}
            />
          </RTGrid>
          <RTGrid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              variant="gradient"
              color="dark"
              title="Premium"
              description="Free access for 200 members"
              price={{ value: "$499", type: "year" }}
              action={{ type: "internal", route: "/", label: "sign_in" }}
              specifications={[
                "Complete documentation",
                "Working materials in Sketch",
                "20GB cloud storage",
                "100 team members",
              ]}
            />
          </RTGrid>
          <RTGrid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Enterprise"
              description="Free access for all members"
              price={{ value: "$899", type: "year" }}
              action={{ type: "internal", route: "/", label: "sign_in" }}
              specifications={[
                "Complete documentation",
                "Working materials in Sketch",
                "100GB cloud storage",
                "500 team members",
                "Premium support",
              ]}
            />
          </RTGrid>
        </RTGrid>
      </RTContainer>
    </RTBox>
  );
}

export default PricingTwo;`;

export default featuresTwoCode;
